import React, {
  Component,
  useEffect,
  componentDidMount,
  useState,
  useRef,
} from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from '../App.css';
import footer_back from '../../images/footer_back.png';
import CookieConsent from "react-cookie-consent";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false,
      toggleset: '',
      showquicklinks: false,
      showservices: false,
      showextra: false,
    };
  }

  componentDidMount() {
    const scrollComponent = this;
    document.addEventListener('scroll', function(e) {
      scrollComponent.toggleVisibility();
    });

    setTimeout(() => {
      if ($(window).width() + 17 < 481) {
        $('.f_links').css('display', 'none');
        $('.f_services').css('display', 'none');
        $('.f_extra').css('display', 'none');
      } else {
        $('.f_links').css('display', 'block');
        $('.f_services').css('display', 'block');
        $('.f_extra').css('display', 'flex');
      }
    }, 3000);
  }

  toggleVisibility() {
    if (window.pageYOffset > 300) {
      this.setState({
        is_visible: true,
      });
    } else {
      this.setState({
        is_visible: false,
      });
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    const { is_visible } = this.state;
    const url =
    'https://gmail.us21.list-manage.com/subscribe/post-json?u=c6ede716a36e11de1c72717ea&id=9c57fa4a4b&f_id=00a0c1e1f0&c=jQuery190023112860141382852_1673439698120&b_c6ede716a36e11de1c72717ea_9c57fa4a4b=&subscribe=Subscribe&_=1673439698122';
    // $(window).resize(function (value) {
    //     if ($(window).width() + 17 < 481) {
    //         $('.f_links').css('display', 'none')
    //         $('.f_services').css('display', 'none')
    //         $('.f_extra').css('display', 'none')
    //     } else {
    //         $('.f_links').css('display', 'block')
    //         $('.f_services').css('display', 'block')
    //         $('.f_extra').css('display', 'flex')
    //     }
    // });

    const openLinks = (e, type) => {
      if (type == 'links') {
        this.setState({ showquicklinks: !this.state.showquicklinks });
        this.setState({ showservices: false });
        this.setState({ showextra: false });
      } else if (type == 'services') {
        this.setState({ showservices: !this.state.showservices });
        this.setState({ showquicklinks: false });
        this.setState({ showextra: false });
      } else if (type == 'extras') {
        this.setState({ showextra: !this.state.showextra });
        this.setState({ showservices: false });
        this.setState({ showquicklinks: false });
      }
    };

    const Footer = (
      <footer style={{ backgroundImage: `url(${footer_back})` }}>
        <div className="container" style={{ maxWidth: '1380px' }}>
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className={s.f_details}>
                <h4>About Us</h4>
                <p>
                  Being the Industry best Growth marketing company, our
                  unconventional team-best utilizes subliminal advertising
                  practices to make your brand reach the minutest of your
                  audience. Whether to accelerate business growth or to stand
                  out from your competitors, we are here for you every step of
                  the way!{' '}
                </p>
                <ul className={s.social_set}>
                  <li>
                    <a
                      href="https://www.facebook.com/EzioSolutions/"
                      blank="_blank"
                    >
                      <img
                        src={require('../../images/blog/icons/facebook.png')}
                        alt="Ezio Facebook Page"
                        title="Ezio Facebook Page"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/ezio-solutions"
                      blank="_blank"
                    >
                      <img
                        src={require('../../images/blog/icons/linkedin.png')}
                        alt="Ezio Linkedin Page"
                        title="Ezio Linkedin Page"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/eziosolutions/"
                      blank="_blank"
                    >
                      <img
                        src={require('../../images/blog/icons/footinsta.png')}
                        alt="Ezio Instagrm Page"
                        title="Ezio Instagrm Page"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/EzioSolutions" blank="_blank">
                      <img
                        src={require('../../images/blog/icons/twitter.png')}
                        alt="Ezio Twitter Page"
                        title="Ezio Twitter Page"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`col-lg-3 col-sm-6 ${s.mbl_footercontact}`}>
              <div className={s.f_details}>
                <h4>Contact Us</h4>
                <div className={s.footer_info_contact}>
                  <i className="flaticon-call-answer" />
                  <h6>
                    <span className={s.ex_icon}>
                      <img
                        src={require('../../images/footer/phone.png')}
                        alt="Ezio Call Button"
                        title="Ezio Call Button"
                      />
                    </span>
                    Phone
                  </h6>
                  <p className="mb-2">
                  <a href="tel:0422 3502500">0422 3502500</a>
                    {/* <a href="tel:+14 129450263">+14 129450263</a>
                    <a href="tel:+91 7530093686">+91 7530093686</a> */}
                  </p>
                </div>
                <div className={s.footer_info_contact}>
                  <i className="flaticon-envelope" />
                  <h6>
                    <span className={s.ex_icon}>
                      <img
                        src={require('../../images/footer/mail.png')}
                        alt="Ezio Mail Button"
                        title="Ezio Mail Button"
                      />
                    </span>
                    Email
                  </h6>
                  <p>
                    <a href="mailto:connect@eziosolutions.com">
                      connect@eziosolutions.com
                    </a>
                  </p>
                  {/* <p><a href="mailto:hr@eziosolutions.com">hr@eziosolutions.com</a></p> */}
                </div>
                <div className={s.footer_info_contact}>
                  <i className="flaticon-placeholder-filled-point" />
                  <h6>
                    <span className={s.ex_icon}>
                      <img
                        src={require('../../images/footer/maps.png')}
                        alt="Ezio Location"
                        title="Ezio Location"
                      />
                    </span>
                    Address
                  </h6>
                  {/* <span> #33/1, 2nd Floor, Kathir Avenue, Aandal Street, Coimbatore, Tamil Nadu 641004</span> */}
                  <div className={s.footer_address_flag}>
                    <span>
                      <img
                        src={require('../../images/india-flag.png')}
                        alt="Ezio Solutions Private Ltd Location in India"
                        title="Ezio Solutions Private Ltd Location in India"
                      />{' '}
                      India
                      <div className={s.flag_address}>
                        4C , Mani Mahal Street, 3rd Floor, Peelamedu, Hope College, Coimbatore,<br></br>  India
                        - 641004.
                      </div>
                    </span>
                    <span>
                      <img
                        src={require('../../images/USA-flag.png')}
                        alt="Ezio Solutions Private Ltd Location in Australia"
                        title="Ezio Solutions Private Ltd Location in Australia"
                      />{' '}
                      USA
                      <div className={s.flag_address}>
                        606 Liberty Ave, Pittsburgh, PA 15222, United States
                      </div>
                    </span>
                    <span>
                      <img
                        src={require('../../images/singapore-flag.png')}
                        alt="Ezio Solutions Private Ltd Location in Singapore"
                        title="Ezio Solutions Private Ltd Location in Singapore"
                      />{' '}
                      Singapore
                      <div className={s.flag_address}>
                        Suntec Tower Three, 8 Temasek Boulevard, Singapore
                        038988
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div
                className={cx(
                  s.f_details,
                  s.f_links_out,
                  this.state.showquicklinks ? s.show : '',
                )}
              >
                <h4 onClick={e => openLinks(e, 'links')}>
                  <span onClick={e => openLinks(e, 'links')}>
                    <i className="fa fa-plus" aria-hidden="true" />
                  </span>{' '}
                  Quick Links
                </h4>
                <ul
                  className={cx(
                    s.f_links,
                    this.state.showquicklinks ? s.show : '',
                  )}
                >
                  <li>
                    <a href="/about-us" onClick={() => window.scrollTo(0, 0)}>
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="/business-verticals"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Business Verticals
                    </a>
                  </li>
                  <li>
                    <a
                      href="/how-we-work"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      How We Work
                    </a>
                  </li>
                  <li>
                    <a href="/careers" onClick={() => window.scrollTo(0, 0)}>
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href="/b2b-membership" onClick={() => window.scrollTo(0, 0)}>
                    B2B Membership
                    </a>
                  </li>
                  <li>
                    <a href="/college-membership" onClick={() => window.scrollTo(0, 0)}>
                    College Membership
                    </a>
                  </li>
                  <li>
                    <a href="/blog" onClick={() => window.scrollTo(0, 0)}>
                      Blogs
                    </a>
                  </li>
                  <li>
                    <a href="/contact-us" onClick={() => window.scrollTo(0, 0)}>
                      Contact
                    </a>
                  </li>
                  {/* <li><a href='/privacy-policy' onClick={() => window.scrollTo(0, 0)}>Privacy Policy</a></li>
                                <li><a href='/terms-and-conditions' onClick={() => window.scrollTo(0, 0)}>Terms and Conditions</a></li>  */}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div
                className={cx(
                  s.f_details,
                  s.f_services_out,
                  this.state.showservices ? s.show : '',
                )}
              >
                <h4 onClick={e => openLinks(e, 'services')}>
                  <span onClick={e => openLinks(e, 'services')}>
                    <i className="fa fa-plus" aria-hidden="true" />
                  </span>{' '}
                  Services
                </h4>
                <ul
                  className={cx(
                    s.f_services,
                    this.state.showservices ? s.show : '',
                  )}
                >
                  <li>
                    <a
                      href="/digital-marketing-company"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Digital Marketing
                    </a>
                  </li>
                  <li>
                    <a
                      href="/web-development-company-in-coimbatore"
                      onClick={() => window.scrollTo(0, 0)}
                      className={s.footer_webdev_res}
                    >
                      Web <span>Development</span>
                      <span>Dev</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/nft-marketing-agency"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      NFT Marketing
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/video-marketing"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Video Marketing
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="/affiliate-marketing"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Affiliate Marketing
                    </a>
                  </li>
                  <li>
                    <a
                      href="/logo-design-company-in-coimbatore"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Logo Designing
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/seo-services-company"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      SEO
                    </a>
                  </li>
                  <li>
                    <a
                      href="/social-media-marketing-company"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      SMO
                    </a>
                  </li>
                  <li>
                    <a
                      href="/ppc-services-company"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      PPC Advertising
                    </a>
                  </li>
                  <li>
                    <a
                      href="/fb-advertising"
                      onClick={() => window.scrollTo(0, 0)}
                      className={s.footer_fb_res}
                    >
                      <span>Facebook </span>
                      <span>FB </span> Advertising
                    </a>
                  </li>
                  <li>
                    <a
                      href="/app-store-optimization-company"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      ASO
                    </a>
                  </li>
                  <li>
                    <a
                      href="/email-marketing"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Email Marketing
                    </a>
                  </li> */}
                  {/* <li><a href='/ui-ux-design' onClick={() => window.scrollTo(0, 0)}>UI & UX Design</a></li>
                                <li><a href='/web-development' onClick={() => window.scrollTo(0, 0)}>Web Development</a></li> */}
                </ul>
              </div>
            </div>
            <div className={`col-lg-2 col-sm-6 ${s.dsck_footercontact}`}>
              <div className={cx(s.f_details, s.flastcontmb)}>
                <h4>Contact Us</h4>
                <div className={s.footer_info_contact}>
                  <i className="flaticon-call-answer" />
                  <h6>
                    <span className={s.ex_icon}>
                      <img
                        src={require('../../images/footer/phone.png')}
                        alt="Ezio Call Button"
                        title="Ezio Call Button"
                      />
                    </span>
                    Phone
                  </h6>
                  <p className="mb-2">
                  <a href="tel:0422 3502500">0422 3502500</a>
                    {/* <a href="tel:+14 129450263">+14 129450263</a>
                    <a href="tel:+91 7530093686">+91 7530093686</a> */}
                  </p>
                </div>
                <div className={s.footer_info_contact}>
                  <i className="flaticon-envelope" />
                  <h6>
                    <span className={s.ex_icon}>
                      <img
                        src={require('../../images/footer/mail.png')}
                        alt="Ezio Mail Button"
                        title="Ezio Mail Button"
                      />
                    </span>
                    Email
                  </h6>
                  <p>
                    <a href="mailto:connect@eziosolutions.com">
                      connect@eziosolutions.com
                    </a>
                  </p>
                  {/* <p><a href="mailto:hr@eziosolutions.com">hr@eziosolutions.com</a></p> */}
                </div>
                <div className={s.footer_info_contact}>
                  <i className="flaticon-placeholder-filled-point" />
                  <h6>
                    <span className={s.ex_icon}>
                      <img
                        src={require('../../images/footer/maps.png')}
                        alt="Ezio Location"
                        title="Ezio Location"
                      />
                    </span>
                    Address
                  </h6>
                  {/* <span> #33/1, 2nd Floor, Kathir Avenue, Aandal Street, Coimbatore, Tamil Nadu 641004</span> */}
                  <div className={s.footer_address_flag}>
                    <span>
                      <img
                        src={require('../../images/india-flag.png')}
                        alt="Ezio Solutions Private Ltd Location in India"
                        title="Ezio Solutions Private Ltd Location in India"
                      />
                      India
                      <div className={s.flag_address}>
                      4C , Mani Mahal Street, 3rd Floor, Peelamedu, Hope College, Coimbatore,<br></br> India
                        - 641004.
                      </div>
                    </span>
                    <span>
                      <img
                        src={require('../../images/USA-flag.png')}
                        alt="Ezio Solutions Private Ltd Location in Australia"
                        title="Ezio Solutions Private Ltd Location in Australia"
                      />
                      USA
                      <div className={s.flag_address}>
                        606 Liberty Ave, Pittsburgh, PA 15222, United States
                      </div>
                    </span>
                    <span>
                      <img
                        src={require('../../images/singapore-flag.png')}
                        alt="Ezio Solutions Private Ltd Location in Singapore"
                        title="Ezio Solutions Private Ltd Location in Singapore"
                      />
                      Singapore
                      <div className={s.flag_address}>
                        Suntec Tower Three, 8 Temasek Boulevard, Singapore
                        038988
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-lg-3 col-sm-6 ${s.subscribeForm}`}>
             
             <h4>Stay in Touch</h4>
             <p>
               Subscribe to keep track of the latest happenings and exciting
               updates.{' '}
             </p>
             <MailchimpSubscribe url={url} />
           </div>
          </div>

          {/* <div className='col-lg-8 extra_links'>
                                   <div className='f_details f_extra_out'>
                                       <h4 className='extralink_headi' onClick={e => openLinks(e, 'extras')} ><span onClick={e => openLinks(e, 'extras')}><i class="fa fa-plus" aria-hidden="true"></i></span> Extra Links</h4>
                                       <ul className='f_extra'>
                                           <li><a href='/faq' onClick={() => window.scrollTo(0, 0)}>FAQ</a></li>
                                           <li><a href='/careers' onClick={() => window.scrollTo(0, 0)}>Career</a></li>
                                           <li><a href='/privacy-policy' onClick={() => window.scrollTo(0, 0)}>Privacy Policy</a></li>
                                           <li><a href='/terms-and-conditions' onClick={() => window.scrollTo(0, 0)}>Terms and Conditions</a></li>
                                           <li><a href='/grievance' onClick={() => window.scrollTo(0, 0)}>Grievance</a></li>
                                           <li><a href='/safety' onClick={() => window.scrollTo(0, 0)}>Safety</a></li>
                                           <li><a href='/bounty' onClick={() => window.scrollTo(0, 0)}>Bounty Program</a></li>
                                       </ul>
                                   </div>
                               </div>
                               */}
          <div className={s.extra_links}>
            <div
              className={cx(
                s.f_details,
                s.f_extra_out,
                this.state.showextra ? s.show : '',
              )}
            >
              <h4
                className={s.extralink_headi}
                onClick={e => openLinks(e, 'extras')}
              >
                <span onClick={e => openLinks(e, 'extras')}>
                  <i className="fa fa-plus" aria-hidden="true" />
                </span>{' '}
                Extra Links
              </h4>
              <ul className={cx(s.f_extra, this.state.showextra ? s.show : '')}>
                {/* <li><Link to='/faq' onClick={() => window.scrollTo(0, 0)}>FAQ</Link></li> */}
                {/* <li><Link to='/careers' onClick={() => window.scrollTo(0, 0)}>Career</Link></li> */}
                <li>
                  <a
                    href="/privacy-policy"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="/terms-and-conditions"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Terms and Conditions
                  </a>
                </li>
                {/* <li><Link to='/grievance' onClick={() => window.scrollTo(0, 0)}>Grievance</Link></li> */}
                <li>
                  <a href="/safety" onClick={() => window.scrollTo(0, 0)}>
                    Safety
                  </a>
                </li>
                <li>
                  <a href="/bounty" onClick={() => window.scrollTo(0, 0)}>
                    Bounty Program
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className={`col-lg-12 ${s.copy_rights}`}>
            <p>2023 © EZIO SOLUTIONS PRIVATE LIMITED. All RIGHTS RESERVED</p>
          </div>
        </div>

        <span className={s.footsocicn_s2}>
        <a
href="https://api.whatsapp.com/send?phone=917530093686&text=Hi,%20I%20would%20like%20to%20grow%20my%20business.%20Looking%20for%20website%20development,%20branding,%20inquires%20%26%20sales."
target="_blank"
rel="noreferrer"

>
            <img
              src={require('../../images/footer/foot-soci-wts.png')}
              alt="Ezio Solutions Private Ltd Location in WhatsApp"
              title="Ezio Solutions Private Ltd Location in WhatsApp"
            />
          </a>
        </span>


         {/* <CookieConsent
            location="bottom"
            buttonText="Okay"
            cookieName="consent"
            style={{ background: "#0c1a41",maxWidth:'450px',borderRadius:'10px',left:'50%',transform:'translate(-50%,-15px)',boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
            buttonStyle={{ color: "#000", fontSize: "14px",borderRadius:'5px',fontWeight:'bold'}}
            expires={150}
            >
            This website uses cookies
          </CookieConsent>   */}
        {/* <span className='footsocicn-s2'><a href='' ><img src={process.env.PUBLIC_URL + '/images/footer/foot-soci-msg.png'} alt='' /></a></span> */}

        <div className={s.scroll_to_top}>
          {is_visible && (
            <div onClick={() => this.scrollToTop()}>
              <img
                src={require('../../images/footer/movetop-foot.png')}
                alt="Go to top"
                title="Go to top"
              />
            </div>
          )}
        </div>
      </footer>
    );

    return <React.Fragment>{Footer}</React.Fragment>;
  }
}

export default withStyles(s)(Footer);
