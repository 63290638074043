import React, { useState, useEffect } from 'react';
import {
  useHistory,
  useLocation,
  Route,
  Switch,
  BrowserRouter as Routes,
  StaticRouter,
} from 'react-router-dom';
import { createMemoryHistory } from 'history';
import Header from '../Header';
import Footer from '../Footer';
import s from '../../../public/css/bootstrap.min.css';
import ServicePopup from '../ServicePopup/ServicePopup';

// external-global styles must be imported in your JS.

const history = createMemoryHistory();

export default function Layout({ children }) {
  const [audit_hide, sethide] = useState([]);

  const [stickyPopup,setStickyPopup] = useState(false)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      sethide(window.location.href.split('/'));
    }
  }, []);

  useEffect(()=>{
    if(audit_hide?.[3]==='digital-marketing-company'){
      setTimeout(()=>{
        document.getElementById('enquiry_form')?.click()
      },3000)
    }
  },[audit_hide])

  const stickyButton={
    display: 'grid',
    alignItems: 'center', 
    justifyItems: 'center',
    position: 'fixed',
    zIndex: '1000',
    cursor: 'pointer',
    top: '350px',
    right: '-45px',
    transform: 'rotate(-90deg)',
    height: '30px',
    width: '120px',
    backgroundColor: '#ffe500',
    color: '#000',
  }

  const servicePages=[
    'seo',
    'social-media-marketing-company',
    'ppc-services-company',
    'fb-advertising',
    'app-store-optimization-company',
    'native-advertising',
    'video-marketing',
    'email-marketing',
    'web-development-company-in-coimbatore',
    'ui-ux-design-company',
    'logo-design-company-in-coimbatore',
    'nft-marketing-agency',
    'digital-marketing-company',
  ]
// console.log("audit_hide",audit_hide)
  return (
    <StaticRouter history={history}>
      <link rel="stylesheet" href="/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/css/font-awesome.min.css" />
      <link
        rel="stylesheet"
        href="/css/font-awesome.min.css"
        integrity="sha512-FEQLazq9ecqLN5T6wWq26hCZf7kPqUbFC9vsHNbXMJtSZZWAcbJspT+/NEAQkBfFReZ8r9QlA9JHaAuo28MTJA=="
        crossOrigin="anonymous"
      />
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="/css/slick.min.css"
      />
      <link rel="stylesheet" type="text/css" href="/css/slick-theme.min.css" />
      <link rel="stylesheet" href="/css/style.css" />
      <link rel="stylesheet" href="/css/modal-video.min.css" />
      <link rel="stylesheet" href="/css/styles.css" />
      <script
        src="/js/jquery-3.6.0.min.js"
        integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4="
        crossOrigin="anonymous"
      />
      <script
        src="/js/bootstrap.min.js"
        integrity="sha384-+YQ4JLhjyBLPDQt//I+STsc9iw4uQqACwlvpslubQzn4u2UU2UFM80nGisd026JF"
        crossOrigin="anonymous"
      />

      <Header />
      {children}
      {/* <Feedback /> */}
      {audit_hide[3] === 'site-audit' ||
      audit_hide[3] === 'free-seo-site-audit' ||
      audit_hide[3] === 'free-trial' ||
      audit_hide[3] === 'marketing-sales' ? null : (
        <Footer />
      )}
      <ServicePopup stickyPopup={stickyPopup} setStickyPopup={setStickyPopup}/>

      {
        servicePages.includes(audit_hide[3])?(
            <div id='enquiry_form' class="enquiry_form" style={stickyButton} onClick={()=>setStickyPopup(true)}>Enquire Now !</div>
        ):null
      }
      
    </StaticRouter>
  );
}
