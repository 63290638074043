import React, { Component, useContext } from 'react';
import $, { nodeName } from 'jquery';
import logo from '../../../logo.png';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Link, withRouter } from 'react-router-dom';
import withStyles from 'isomorphic-style-loader/withStyles';
import axios from 'axios';
import cx from 'classnames';
import s from '../App.css';
import { SiteRouteContext } from './TheLayout';

class StickyHeader extends Component {
  constructor(props) {
    super();

    // console.log(document.URL.split('/').pop())

    this.state = {
      sideStatus: true,
      logged:
        typeof window !== 'undefined' ? localStorage.getItem('logged') : null,
      id: typeof window !== 'undefined' ? localStorage.getItem('id') : null,
      username:
        typeof window !== 'undefined' ? localStorage.getItem('username') : null,
      email:
        typeof window !== 'undefined' ? localStorage.getItem('email') : null,
      clang: 'English',
      toglang: false,
      open: false,
      servicelist: [],
      fields: {
        uname: '',
        email: '',
        phone: '',
        course: '',
      },
      errors: {
        uname: '',
        email: '',
        phone: '',
        course: '',
      },
      hideheader: [],
      mblShow:false,
    };

    this.wrapperRef = React.createRef();

    this.handleClickOutside = this.handleClickOutside.bind(this);
    // this.GetServiceList();
  }

  //   home pop show function
  openHomePopUpHandler = () => {
    if (typeof window !== 'undefined') {
      document.querySelector('.popup-w').style.display = 'flex';
    }
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    console.log(s);
    this.getHeaders = this.getHeaders.bind(this);
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
    // if ($(window).width() < 600) {
    //   $('.navbar-collapse .navbar-nav.ml-auto').slideUp(300);
    // }
    if (typeof window !== `undefined`) {
      this.setState({
        hideheader: window.location.href.split('/'),
      });
      // console.log(this.state.hideheader)
    }

    // setTimeout(() =>{
    //     console.log(window.location.href.split('/')[3])
    // },1000)
    const path = window.location.pathname;
    let activeNav;
    if (path === '/') {
      activeNav = document.getElementById('home');
    } else if (
      // path === '/seo-services-company' ||
      path === '/seo' ||
      path === '/smo' ||
      path === '/ppc-services-company' ||
      path === '/fb-advertising' ||
      path === '/app-store-optimization-company' ||
      path === '/native-advertising' ||
      path === '/affiliate-marketing' ||
      path === '/email-marketing' ||
      path === '/web-development-company-in-coimbatore' ||
      path === '/ui-ux-design-company' ||
      path === '/logo-design-company-in-coimbatore' ||
      path === '/nft-marketing-agency' ||
      path === '/video-marketing' ||
      path === '/digital-marketing-company'
    ) {
      activeNav = document.getElementById('services');
    } else if (path === '/business-verticals') {
      activeNav = document.getElementById('business-verticals');
    } else if (path === '/how-we-work') {
      activeNav = document.getElementById('how-we-work');
    } else if (path === '/b2b-membership' || path === '/college-membership') {
      activeNav = document.getElementById('membership');
    } else if (path === '/careers') {
      activeNav = document.getElementById('career');
    } else if (path === '/blog') {
      activeNav = document.getElementById('blog');
    } else if (path === '/contact-us') {
      activeNav = document.getElementById('contact-us');
    }
    if (activeNav) {
      activeNav.style.setProperty('color', '#ED1D25', 'important');
      activeNav.style.fontWeight = 'bold';
    }

    if ($(window).width() < 480) {
      this.setState({mblShow:true})
    }
  }

  handleClickOutside(event) {
    // if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
    //   // $('.nav-item .sub_service , .nav-item  .sub_membership').fadeOut();
    // }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  getHeaders = () => {
    this.props.HeaderSet('adminhead');
  };

  clanghand(lang) {
    this.setState({
      clang: lang,
      toglang: false,
    });
    console.log(lang);
  }

  langtog = () => {
    console.log(this.state.toglang);
    if (this.state.toglang === false) {
      this.setState({
        toglang: true,
      });
    } else {
      this.setState({
        toglang: false,
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  render() {
    const { open } = this.state;
    const getServices = e => {
      e.preventDefault();

      if ($(window).width() < 480) {
          $('#service_sub_menu').toggle()
      }
      
      if ($(window).width() < 1200) {
        $('.sub_service').slideToggle();
        $('.members_list').slideUp();
        $('.tools_list').slideUp();
        $('.course_list').slideUp();
        $('.user_list').slideUp();
       
      //  $('#service_sub_menu').css('display','none')
      }
     
    };

    const GotoMembership = e => {
      e.preventDefault();
      
      if ($(window).width() < 480) {
        $('#products_sub_menu').toggle()
    }

      if ($(window).width() < 1200) {
        $('.members_list').slideToggle();
        $('.sub_service').slideUp();
        $('.tools_list').slideUp();
        $('.course_list').slideUp();
        $('.user_list').slideUp();
      }
      
    };

    // const GotoTools = (e) => {
    //     e.preventDefault();
    //     if ($(window).width() < 1200) {
    //         $('.tools_list').slideToggle();
    //         $('.sub_service').slideUp();
    //         $('.members_list').slideUp();
    //         $('.course_list').slideUp();
    //         $('.user_list').slideUp();
    //     }
    // }

    // const GotoCourse = (e) => {
    //     e.preventDefault();
    //     if ($(window).width() < 1200) {
    //         $('.course_list').slideToggle();
    //         $('.sub_service').slideUp();
    //         $('.members_list').slideUp();
    //         $('.tools_list').slideUp();
    //         $('.user_list').slideUp();
    //     }
    // }

    const GotoUser = e => {
      e.preventDefault();
      if ($(window).width() < 1200) {
        $('.user_list').slideToggle();
        $('.course_list').slideUp();
        $('.sub_service').slideUp();
        $('.members_list').slideUp();
        $('.tools_list').slideUp();
      }
    };

    const goLink = e => {
      this.props.history.push('/contact-us');
      console.log(this.props);
    };

    const changePass = e => {
      console.log(e);
      if (e === 1) {
        localStorage.clear();
        window.location.reload();
        // this.props.history.push('/')
      }
    };

    // if(typeof window !== 'undefined'){
    //     $( document ).ready(function() {
    //         if ($(window).width() < 1200) {
    //             $('.navbar-collapse').slideUp(300);
    //             $('.navbar-collapse .navbar-nav.ml-auto').slideUp(300);
    //         }
    //     });
    // }
    function showmobileMenu(e) {
      e.preventDefault();
      // if ($(window).width() < 1200) {
      //    $('.navbar-collapse').slideToggle(300);
      //    $('.navbar-collapse .navbar-nav.ml-auto').slideToggle(300);
      // }
    }
    // $('.sub_service .nav-link').click(() => {
    //     if ($(window).width() < 1200) {
    //         $('.navbar-collapse').slideUp(300);
    //     }
    // });

    // $('.nav-item.set_arrow > ul li a.nav-link').click(() => {
    //     if ($(window).width() < 1200) {
    //         $('.navbar-collapse').slideUp(300);
    //         $('.nav-item.set_arrow > ul').slideUp(300);
    //     }
    // });

    // $('.navbar-nav .single-navitem .nav-link').click(() => {
    //     if ($(window).width() < 1200) {
    //         $('.navbar-collapse').slideUp(300);
    //     }
    // })
    // $('.navbar-nav .sub_service .nav-link').click(() => {
    //     if ($(window).width() < 1200) {
    //         $('.navbar-collapse').slideUp(300);
    //     }
    // })
    // $('.navbar-nav .sub_membership .nav-link').click(() => {
    //     if ($(window).width() < 1200) {
    //         $('.navbar-collapse').slideUp(300);
    //     }
    // })

    return (
      <React.Fragment>
        <div className={s.site_header}>
          {this.state.hideheader[3] == 'site-audit' ||
          this.state.hideheader[3] == 'free-seo-site-audit' ||
          this.state.hideheader[3] == 'free-trial' ||
          this.state.hideheader[3] == 'marketing-sales' ? null : (
            <div className={s.t_bar}>
              <ul>
                <li className={s.lang_select}>
                  <i className="fa fa-globe" />
                  <span>{this.state.clang}</span>
                </li>

                <li>
                  <a href="mailto:connect@eziosolutions.com">
                    <i className="fa fa-envelope" aria-hidden="true" />
                    &nbsp;&nbsp;connect@eziosolutions.com
                  </a>
                </li>
                <li>
                  <a
                    href="https://biz.eziosolutions.com"
                    target="_blank"
                    style={{}}
                    rel="noreferrer"
                  >
                    <button
                      style={{
                        backgroundColor: 'transparent',
                        float: 'right',
                        marginTop: '8px',
                        marginBottom: '8px',
                        color: '#fff',
                        border: '1px solid #fff',
                      }}
                    >
                      Log In
                    </button>
                  </a>
                </li>
                <li>
                  <a href="/free-trial" style={{}}>
                    <button
                      style={{
                        backgroundColor: '#00B170',
                        float: 'right',
                        marginTop: '8px',
                        marginBottom: '8px',
                        color: '#fff',
                        border: '1px solid #00B170',
                      }}
                    >
                      Free Consultation
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          )}

          {this.state.hideheader[3] == 'site-audit' ||
          this.state.hideheader[3] == 'marketing-sales' ||
          this.state.hideheader[3] == 'free-seo-site-audit' ? null : (
            <div className={cx(s.header_back, s.sticky_inner)}>
              <div className={cx(s.padding_none, s.res_container)}>
                <nav
                  className={cx(
                    `navbar navbar-expand-xl navbar-light ${s.pad_set}`,
                  )}
                >
                  {/* react cool image library is used for image optimization */}
                  <a
                    href="/"
                    onClick={this.scrollToTop}
                    className={`navbar-brand ${s.n_brand}`}
                  >
                    <img
                      placeholder="ezio-solutions"
                      src={require('../../images/logo.png')}
                      alt="Ezio Growth Marketing Company In Coimbatore india Logo"
                      title=" Ezio Growth Marketing Company In Coimbatore india Logo"
                    />
                  </a>

                  <button
                    onClick={e => showmobileMenu(e)}
                    type="button"
                    className="navbar-toggler bg-light"
                    data-toggle="collapse"
                    data-target="#nav"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>

                  <div
                    className="collapse navbar-collapse justify-content-between"
                    id="nav"
                    // style={{ display: 'block' }}
                  >
                    <ul className="navbar-nav ml-auto">
                      <li
                        className={`nav-item ${cx(
                          s.single_navitem,
                          s.drpdwnselect,
                        )}`}
                      >
                        {/* used context value from SiteRouteContext from TheLayout.js component */}
                        <SiteRouteContext.Consumer>
                          {value => (
                            <a
                              id="home"
                              href="/"
                              className={`nav-link text-light  px-3 ${s.n_link}`}
                              onClick={value}
                            >
                              Home
                            </a>
                          )}
                        </SiteRouteContext.Consumer>
                      </li>
                      <li
                        className={`nav-item ${cx(
                          s.set_arrow,
                          s.service,
                          s.drpdwnselect,
                        )}`}
                      >
                        {/* <a className={`nav-link text-light  px-3 ${s.n_link}`} href="#about">Service</a> */}
                        <a
                          id="services"
                          ref={this.wrapperRef}
                          className={`nav-link text-light  px-3 ${s.n_link}`}
                          href="#"
                          onClick={e => getServices(e)}
                        >
                          Services
                        </a>

                        <ul id="service_sub_menu" className={s.sub_service} style={this.state.mblShow?{display:'none'}:{}}>
                          <li>
                            <ul>
                              <li>
                                <a
                                  // href="/seo-services-company"
                                  href="/seo"
                                  className={`nav-link text-light  px-3 ${cx(
                                    s.n_link,
                                    s.li_line,
                                  )}`}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  SEO
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/social-media-marketing-company"
                                  className={`nav-link text-light  px-3 ${cx(
                                    s.n_link,
                                    s.li_line,
                                  )}`}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  SMM
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/ppc-services-company"
                                  className={`nav-link text-light  px-3 ${cx(
                                    s.n_link,
                                    s.li_line,
                                  )}`}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  PPC Advertising
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/fb-advertising"
                                  className={`nav-link text-light  px-3 ${s.n_link}`}
                                  style={{ paddingBottom: '10px' }}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  Facebook Advertising
                                </a>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <a
                                  href="/app-store-optimization-company"
                                  className={`nav-link text-light  px-3 ${cx(
                                    s.n_link,
                                    s.li_line,
                                  )}`}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  ASO
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/native-advertising"
                                  className={`nav-link text-light  px-3 ${cx(
                                    s.n_link,
                                    s.li_line,
                                  )}`}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  Native Advertising
                                </a>
                              </li>
                              {/* <li>
                                <a
                                  href="/affiliate-marketing"
                                  className={`nav-link text-light  px-3 ${cx(
                                    s.n_link,
                                    s.li_line,
                                  )}`}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  Affiliate Marketing
                                </a>
                              </li> */}
                              <li>
                                <a
                                  href="/video-marketing"
                                  className={`nav-link text-light  px-3 ${cx(
                                    s.n_link,
                                    s.li_line,
                                  )}`}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  Video Marketing
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/email-marketing"
                                  className={`nav-link text-light  px-3 ${s.n_link}`}
                                  style={{ paddingBottom: '10px' }}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  Email Marketing
                                </a>
                              </li>{' '}
                            </ul>
                            <ul>
                              <li>
                                <a
                                  href="/web-development-company-in-coimbatore"
                                  className={`nav-link text-light  px-3 ${cx(
                                    s.n_link,
                                    s.li_line,
                                  )}`}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  Web Development
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/ui-ux-design-company"
                                  className={`nav-link text-light  px-3 ${cx(
                                    s.n_link,
                                    s.li_line,
                                  )}`}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  UI&UX Design
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/logo-design-company-in-coimbatore"
                                  className={`nav-link text-light  px-3 ${cx(
                                    s.n_link,
                                    s.li_line,
                                  )}`}
                                  style={{ paddingBottom: '10px' }}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  Logo Designing
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/nft-marketing-agency"
                                  className={`nav-link text-light  px-3 ${s.n_link}`}
                                  style={{ paddingBottom: '10px' }}
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  NFT Marketing
                                </a>
                              </li>{' '}
                            </ul>
                            {/* <ul>
                                                        
                                                        <li><Link to='/service' className={`nav-link text-light  px-3 ${s.n_link}`} >Ecommerce- Product Listing Service</Link></li>
                                                    </ul> */}
                          </li>
                        </ul>
                      </li>
                      <li
                        className={`nav-item ${cx(
                          s.single_navitem,
                          s.drpdwnselect,
                        )}`}
                      >
                        <a
                          id="business-verticals"
                          href="/business-verticals"
                          className={`nav-link text-light  px-3 ${s.n_link}`}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          Business Verticals
                        </a>
                      </li>

                      <li
                        className={`nav-item ${cx(
                          s.single_navitem,
                          s.drpdwnselect,
                        )}`}
                      >
                        <a
                          id="contact-us"
                          className={`nav-link text-light  px-3 ${s.n_link}`}
                          href="https://portfolio.eziosolutions.com/"
                          onClick={() => window.scrollTo(0, 0)}
                          target="_blank"
                          rel="noopener noreferrer" 
                        >
                          Portfolio
                        </a>
                      </li>

                      {/* <li
                        className={`nav-item ${cx(
                          s.single_navitem,
                          s.drpdwnselect,
                        )}`}
                      >
                        <a
                          id="how-we-work"
                          className={`nav-link text-light  px-3 ${s.n_link}`}
                          href="/how-we-work"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          How We Work
                        </a>
                      </li> */}

                      <li
                        className={`nav-item ${cx(
                          s.set_arrow,
                          s.drpdwnselect,
                        )}`}
                      >
                        <a
                          id="membership"
                          className={`nav-link text-light  px-3 ${s.n_link}`}
                          href="#"
                          onClick={e => GotoMembership(e)}
                          
                        >
                          Products 
                        </a>
                        <ul id='products_sub_menu' className={cx(s.sub_membership, s.members_list)} style={this.state.mblShow?{display:'none'}:{}}>
                          <li>
                            <a
                              href="https://ezcrawl.in/"
                              className={`nav-link text-light  px-3 ${cx(
                                s.n_link,
                                s.li_line,
                              )}`}
                              onClick={() => window.scrollTo(0, 0)}
                              target="_blank"
                              rel="noopener noreferrer" 
                            >
                              EZCRAWL 
                            </a>{' '}
                          </li>
                          <li>
                            <a
                              href="https://www.ezlearnhive.com/"
                              className={`nav-link text-light  px-3 ${cx(
                                s.n_link,
                                s.li_line,
                              )}`}
                              onClick={() => window.scrollTo(0, 0)}
                              target="_blank"
                              rel="noopener noreferrer" 
                            >
                              EZ LEARNHIVE 
                            </a>{' '}
                          </li>
                          {/* <li>
                            <a
                              href="/college-membership"
                              className={`nav-link text-light  px-3 ${s.n_link}`}
                              onClick={() => window.scrollTo(0, 0)}
                            >
                              College Membership
                            </a>{' '}
                          </li> */}
                        </ul>
                      </li>
                      <li
                        className={`nav-item ${cx(
                          s.single_navitem,
                          s.drpdwnselect,
                        )}`}
                      >
                        <a
                          id="career"
                          className={`nav-link text-light  px-3 ${s.n_link}`}
                          href="/careers"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          Careers
                        </a>
                      </li>
                      <li
                        className={`nav-item ${cx(
                          s.single_navitem,
                          s.drpdwnselect,
                        )}`}
                      >
                        <a
                          id="blog"
                          className={`nav-link text-light  px-3 ${s.n_link}`}
                          href="/blog"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          Blogs
                        </a>
                      </li>
                      <li
                        className={`nav-item ${cx(
                          s.single_navitem,
                          s.drpdwnselect,
                        )}`}
                      >
                        <a
                          id="contact-us"
                          className={`nav-link text-light  px-3 ${s.n_link}`}
                          href="/contact-us"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          Contact
                        </a>
                      </li>
                      
                      {/* <li>
                        <a
                          href="https://dev-biz.eziosolutions.com"
                          target="_blank"
                          className={`btn btn-success ${s.login_set}`}
                          rel="noreferrer"
                        >
                          Login
                        </a>
                      </li> */}
                      {/* <li className="nav-item set_arrow">
                                                                                   <Link className={`nav-link text-light  px-3 ${s.n_link}`} to='#' onClick={e => GotoCourse(e) }>Online Course</Link>
                                                                                   <ul className='sub_membership course_list'>
                                                                                       <li><a href='https://master.d2mr8y54eg4tmp.amplifyapp.com/' className={`nav-link text-light  px-3 ${s.n_link}`}>Digital Marketing</a> </li>
                                                                                   </ul>
                                       
                                                                               </li> */}
                      {/* <li className="nav-item set_arrow">
                                            <Link className={`nav-link text-light  px-3 ${s.n_link}`} to='#' onClick={e => GotoTools(e) }>Tools</Link>
                                            <ul className='sub_membership tools_list'>
                                                <li><Link to='/b2b-membership' className={`nav-link text-light  px-3 ${s.n_link}`}>Email Scrabber</Link> </li>
                                                <li><Link to='/college-membership' className={`nav-link text-light  px-3 ${s.n_link}`}>Data Scrabber</Link> </li>
                                            </ul>
                                        </li> */}
                      {/* <li className={`nav-item ${s.single_navitem}`}>
                                            <a className={`nav-link text-light  px-3 ${s.n_link}`} href='/contact' onClick={(e)=> goLink(e)}>Contact</a>
                                        </li> */}
                      {/* {this.state.logged ? (
                      <li className={`nav-item ${s.set_arrow}`}>
                        <a
                          className={`nav-link text-light  px-3 ${s.n_link}`}
                          to="#"
                          onClick={e => GotoUser(e)}
                        >
                          Hello {this.state.username}{' '}
                        </a>
                        <ul className={cx(s.sub_membership, s.user_list)}>
                          <li>
                            <a
                              href="/dashboard"
                              className={`nav-link text-light  px-3 ${s.n_link}`}
                            >
                              Dashboard
                            </a>
                          </li>
                          <li onClick={e => changePass(1)}>
                            <a
                              href="/"
                              className={`nav-link text-light  px-3 ${s.n_link}`}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      <li
                        className={`nav-item ${cx(
                          s.login_out,
                          s.single_navitem,
                        )}`}
                      >
                        <a
                          href="/contact-us"
                          target="_blank"
                          className={`btn btn-success ${s.login_set}`}
                        >
                          Login
                        </a>
                      </li>
                    )} */}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          )}
        </div>

        <div className="site_dinamic_header" />
      </React.Fragment>
    );
  }
}

export default withStyles(s)(withRouter(StickyHeader));
