// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DChMC{background:#fff}.DChMC .k3Ycd{height:600px;background-size:cover}.Ic3BA{padding-left:25px;border-left:5px solid #f30505;left:auto;margin-left:100px}.Ic3BA h2{font-size:70px;font-family:proxima-bold;text-transform:uppercase}._2ahws{position:relative;font-size:50px;padding-left:35px;font-family:proxima-bold;color:#333;margin-bottom:30px}.DChMC p{font-family:proxima-regular;color:#000;font-size:16px;line-height:30px}._2ahws:before{position:absolute;content:'\"';top:0;left:0}._1_532,._2Hyu2{line-height:24px}._25ahX{text-align:center;margin-top:40px;margin-bottom:40px;padding-top:10px;padding-bottom:10px;border-left:2px solid #ccc}._1n3QW{border-left:none!important}._25ahX h2{font-size:40px}._25ahX h2,._25ahX p{color:#fff;margin-bottom:0;padding-bottom:0}._1x1Uv{float:left;width:100%;background:#0c1a41}", ""]);
// Exports
exports.locals = {
	"about-contsec": "DChMC",
	"contact_banner": "k3Ycd",
	"abt-ban-cont": "Ic3BA",
	"abt-complex": "_2ahws",
	"abt-complex-left": "_1_532",
	"lnh-24": "_2Hyu2",
	"achive-box": "_25ahX",
	"achive-box-1": "_1n3QW",
	"t_bar": "_1x1Uv"
};
module.exports = exports;
