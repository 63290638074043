import React, { Component, useRef, Fragment, useEffect, useState } from 'react';
import $ from 'jquery';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Header.css';
import StickyHeader from './stickyheader';
import TagManager from 'react-gtm-module';
import { Person } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

export const Stickyh = () => {
  useStyles(s);

   if (process.browser) {
    const tagManagerArgs = {
      gtmId: 'GTM-WSRTVSW'
  }   
  TagManager.initialize(tagManagerArgs)

  }

  const [isSticky, setSticky] = useState('');
  const ref = useRef(null);

  const handleScroll = () => {
    if (window.pageYOffset >= 350) {
      setTimeout(
        function() {
          setSticky(true);
        }.bind(this),
        200,
      );
    } else if (window.pageYOffset <= 300) {
      setTimeout(
        function() {
          setSticky(false);
        }.bind(this),
        200,
      );
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <Helmet
        script={[
          helmetJsonLdProp <
            Person >
            {
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: 'Ezio Solutions',
              url: 'https://eziosolutions.com/',
              logo: ' https://www.eziosolutions.com/logo.png',
              legalName: 'Ezio Solutions Private Limited',
              address: {
                '@type': 'PostalAddress',
                streetAddress: ' #33/1, 2nd Floor, Kathir Avenue,',
                addressLocality: ' Aandal Street, Coimbatore,',
                addressRegion: 'Tamil Nadu',
                postalCode: '641004',
                addressCountry: 'India',
              },
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'customer support',
                telephone: '+91 7530093686',
                email: 'connect@eziosolutions.com',
              },

              sameAs: [
                'https://www.facebook.com/EzioSolutions/',
                'https://www.instagram.com/eziosolutions/',
                'https://twitter.com/EzioSolutions',
                'https://www.linkedin.com/company/ezio-solutions/',
              ],
            },
        ]}
      />

      <div
        className={`sticky-wrapper${isSticky ? ' sticky' : ''}`}
        ref={ref}
        style={{ position: 'sticky', zIndex: '1000', top: '0',boxShadow: '0px 4px 18px rgba(33, 33, 33, 0.08)' }}
      >
        <StickyHeader />
      </div>
    </Fragment>
  );
};

export default class Header extends Component {
  constructor() {
    super();
    // console.log(document.URL.split('/').pop())
  }

  render() {
    return (
      <React.Fragment>
        <Stickyh />
      </React.Fragment>
    );
  }
}
