import React from 'react'
import useStyles from 'isomorphic-style-loader/withStyles';
import s from './App.css';

function CustomModal({open,children,onClose,top,left,bgdark,zindex,radius}) {
  useStyles(s);
  if(!open) return null
    const overLayStyle={
       right:0,
        left:0,
        top:'-10%',
        bottom:'-13%',
        backgroundColor:(bgdark==undefined)?"black":(bgdark==true)?"rgba(0,0,0,.5)":"transparent",
        position:'fixed',
        zIndex:'1000',
        opacity:'65%',
    }
    const modalStyle={
        position:"fixed",
        height:"auto",
        width:'auto',
        backgroundColor:"white",
        top:(top==undefined)?"50%":top+"%",
        left:(left==undefined)?"50%":left+"%",
        transform:"translate(-50%,-50%)",
        zIndex:(zindex==undefined)?'1000':1000+zindex+"",
        color:"black",
        borderRadius:(radius==undefined)?"0px":radius+"px",
    }
   const styling={
        transition:"all 0.5s"
   }
   //console.log("width",window.innerWidth)
    return (
        <div style={styling}>
        <div style={overLayStyle} onClick={onClose}></div>
        <div id={s.CustomModal} style={modalStyle}>
          {children}
        </div>
       </div>
    )
}

export default CustomModal