import React from 'react'
import { Link } from 'react-router-dom'

import error404 from '../../images/error404.png'

import s from '../../components/App.css'
import useStyles from 'isomorphic-style-loader/withStyles'

export default function Error404() {
  useStyles(s)
  return (
    <div className={s.error_404_page}>
      <div className={s.error_content}>
        <img src={error404} alt="error-page-not-found"/>
        <h2>Looks like this page doesn’t exist, but don’t let that stop you.</h2>
        <a href="/">Home</a>
      </div>
    </div>
  )
}
