import React, { useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../Header'
import Footer from  '../Footer'

import {
    Redirect,
    Route,
    Switch
  } from 'react-router-dom'

import routes from '../../routes/index'
import Error404 from '../../routes/not-found/NotFound';

export const SiteRouteContext = React.createContext();

const SiteLayout = () =>{

    const homePageLoadHandler = () => {
      if(window.history.length > 2) {
        // document.querySelector('.popup-w.home-popup').style.display = 'none';
        console.log(document.querySelector('.popup-w.home-popup'));
      }
      // console.log('log');
    }

    return(
      <SiteRouteContext.Provider 
        value={ homePageLoadHandler() }
      >
        <div>
            <Header />
            <Switch>
              {routes.map((route, idx) => {
              console.log(route)
                    return route.component && (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                            <route.component {...props} />
                        )} />
                    )
                  })}
                  {/* not found page */}
                  <Route component={Error404} />
            </Switch>

            <Footer />
        </div>  
      </SiteRouteContext.Provider>

    )
}
 
export default SiteLayout