import React, { useRef, useState, useEffect } from 'react';
import CustomModal from '../CustomModel';
import cx from 'classnames';
import sp from './ServicePopup.css';
import useStyles from 'isomorphic-style-loader/useStyles';
import PhoneInput from 'react-phone-input-2';
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from 'jquery';
import { fadeInUp, bounceInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import axios from 'axios';
import Select from 'react-select';

const styles = {
  fadeInUp1: {
    animation: ' 0.2s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUp2: {
    animation: ' 0.4s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUp3: {
    animation: ' 0.6s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUp4: {
    animation: ' 0.8s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUp5: {
    animation: ' 1s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
  fadeInUp6: {
    animation: ' 1.2s',
    animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
  },
};

function ServicePopup({stickyPopup,setStickyPopup}) {
  useStyles(sp);

  const nameRef = useRef();
  const businessnameRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const commentRef = useRef();
  const companyNameRef = useRef();
  const serviceRef = useRef();
  const selectedServiceRef = useRef();

  const [name, setName] = useState();
  const [businessname, setbusinessName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [comments, setComments] = useState();
  const [companyName, setCompanyName] = useState();
  const [countryCode, setCountryCode] = useState();
  const [servlist, setServlist] = useState();

  const [phonenumberErr, setPhonenumberErr] = useState(false);
  const [serviceErr,setServiceErr] = useState()

  const [service, setService] = useState();
  const [selectedService, setSelectedService] = useState();

  const [open, setOpen] = useState(false);
  const [popExecuted, setPopExecuted] = useState(false);

  // const [section, setSection] = useState({
  //   current: 'home',
  //   previous: '',
  //   service: '',
  // });

  useEffect(()=>{
    getServiceList()
  },[])

  function getServiceList(){
    const formdata = new FormData();
    formdata.append('action', 'selectservice');
    formdata.append('referer', 'ezioaws');
    axios
      .post(
        `https://api.eziosolutions.com/admin-services.php`,
        formdata,
      )
      .then(res => {
        if (res.status == 200) {
          console.log(res.data);
          setServlist(res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  const options = [];

  // servlist !== undefined &&
  // options.push({
  //   label: 'All',
  //   value: 'all',
  //   className: 'service_drop',
  // });

servlist !== undefined &&
  servlist.map((servn, idx) =>
    options.push({
      label: servn.services_name,
      value: servn.id,
      className: 'service_drop',
    }),
  );


  const onChange = opt => {
    //Getvalues(opt);
    setService(opt.label)
    console.log("vall",opt.label);
  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: serviceErr?'2px solid red':'2px solid rgb(202, 202, 202)',
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
     
    }),
  };

  const handleSetOpen = bool => {
    setOpen(bool);
    setPopExecuted(true);
  };

  const setValues = (value, country) => {
    setPhone(value);
    setCountryCode(country.dialCode);
  };
  function validate() {
    let isValid = true;
    if (!name || name.match(/^[A-Za-z ]+$/) == null) {
      isValid = false;
      nameRef.current.style.border = 'solid red 2px';
    } else {
      nameRef.current.style.border = 'solid 1px #CACACA';
    }

    if (!businessname || businessname.match(/^[A-Za-z0-9 ]+$/) == null) {
      isValid = false;
      businessnameRef.current.style.border = 'solid red 2px';
    } else {
      businessnameRef.current.style.border = 'solid 1px #CACACA';
    }

    if (!phone) {
      isValid = false;
      setPhonenumberErr(true);
    } else {
      console.log(phone.length, countryCode);
      if (
        phone.match(/^[0-9\b]+$/) !== null && countryCode == '91'
          ? phone.length > 11
          : phone.length > 9
      ) {
        setPhonenumberErr(false);
      } else {
        isValid = false;
        setPhonenumberErr(true);
      }
    }
    var pattern = new RegExp(
      /^[a-zA-Z0-9][-a-z0-9.@%!#$%&'*+-=?^_`{|}~\/]+@[a-zA-Z0-9]+\.[A-Za-z]+$/,
    );
    if (!email || !pattern.test(email)) {
      isValid = false;
      emailRef.current.style.border = 'solid red 2px';
    } else {
      emailRef.current.style.border = 'solid 1px #CACACA';
    }
    if (!service) {
      isValid = false;
   //   serviceRef.current.style.border = 'solid red 2px';
      setServiceErr(true)
    } else {
    //  serviceRef.current.style.border = 'solid 1px #CACACA';
      setServiceErr(false)
    }
    // if (service) {
    //   if (!selectedService) {
    //     isValid = false;
    //     selectedServiceRef.current.style.border = 'solid red 2px';
    //   } else {
    //     selectedServiceRef.current.style.border = 'solid 1px #CACACA';
    //   }
    // }

    return isValid;
  }

  const handleSubmit = () => {
    if (validate()) {
      $('#smtbtn span').css('display', 'none');
      $('.loader').css('display', 'inline');
      const formdata = new FormData();
      formdata.append('referer', 'ezioaws');
      formdata.append('action', 'addcontact');
      formdata.append('name', name);
      formdata.append('businessname', businessname);
      formdata.append('email', email);
      formdata.append('mobile', phone);
      formdata.append('service', service + ' ' + selectedService);
      // formdata.append('company_name', companyName);
      if (comments) {
        formdata.append('comments', comments);
      }

      formdata.append('country_code', countryCode);
      axios
        .post(
          'https://api.eziosolutions.com/contact-mail.php',
          formdata,
        )
        .then(res => {
          console.log({ res });
          if (res.data.status == 'success') {
            $('.loader').css('display', 'none');
            $('#smtbtn span').css('display', 'inline');
            $('#scs_msg').css('display', 'block');
            setName('');
            setbusinessName('');
            setPhone('');
            setEmail('');
            setComments('');
            setCompanyName('');
            setCountryCode('');
            setTimeout(() => {
              setStickyPopup(false);
            }, 2000);
          }
        });
    }
  };
  const changePage = (prev, curr, service) => {
    setSection({
      current: curr,
      previous: prev,
      service: service ? service : '',
    });
  };
  const closeBtnEnter = e => {
    e.currentTarget.src = require('../../images/service/hover-close.png');
  };
  const closeBtnLeave = e => {
    e.currentTarget.src = require('../../images/service/normal-close.png');
  };
  console.log('-->', service, selectedService);
  return (
    <div>
      <CustomModal open={stickyPopup} radius={20}>
        <img
          className={sp.closebtn}
          onMouseEnter={closeBtnEnter}
          onMouseLeave={closeBtnLeave}
          onClick={() => setStickyPopup(false)}
          src={require('../../images/service/normal-close.png')}
        />
        <div className={sp.modal_container}>
          <div className={sp.service_popup_con}>
            <div className={sp.serv_pop_left_half}>
              <img
                src={require('../../images/service/select-your-service-hand-icon.png')}
                alt="alt"
              />
              <h4>Get access for a Free Consultation.</h4>
              <p>
                Please choose a service for which you are looking for a
                solution!
              </p>
              <div>
                <a
                  href="https://www.facebook.com/EzioSolutions/"
                  target="_blank"
                >
                  <img
                    src={require('../../images/service/facebook.png')}
                    alt="alt"
                  />{' '}
                </a>
                <a href="https://twitter.com/EzioSolutions" target="_blank">
                  {' '}
                  <img
                    src={require('../../images/service/twitter.png')}
                    alt="alt"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/ezio-solutions"
                  target="_blank"
                >
                  <img
                    src={require('../../images/service/linkedin.png')}
                    alt="alt"
                  />
                </a>
                <a
                  href="https://www.instagram.com/eziosolutions/"
                  target="_blank"
                >
                  {' '}
                  <img
                    src={require('../../images/service/instagram.png')}
                    alt="alt"
                  />
                </a>
              </div>
              <p className={sp.serv_pop_note} style={{color:'#ffc700',marginTop:'25px'}}>Note : <b>Job enquiries</b> will not be taken into consideration if you fail to upload your resume.
For job related enquiries, kindly fill the form provided in the <a href='/careers' style={{fontWeight:'bold',color:'#a4d9ff',borderBottom:'1px solid #a4d9ff'}}>Careers page.</a></p>
            </div>
            <div className={sp.serv_pop_right_half}>
              {// section.current==='home'
              false && (
                <StyleRoot>
                  <div className={sp.serv_pop_right_half_inner}>
                    <h3 className={sp.serv_pop_right_half_heading}>
                      Our Services
                    </h3>
                    <p>
                      Let’s talk! All you have to do is choose a service and we
                      will show you how we can help you effectively to achieve
                      your goals.
                    </p>

                    <div className={sp.box_container}>
                      <div
                        className={sp.full_width_box}
                        onClick={() => {
                          changePage(section.current, 'web');
                        }}
                        style={styles.fadeInUp1}
                      >
                        <img
                          src={require('../../images/service/web-designing-and-development.png')}
                          alt="alt"
                        />
                        <p>Web Design And Development</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(section.current, 'digital');
                        }}
                        style={styles.fadeInUp2}
                      >
                        <img
                          src={require('../../images/service/digital-marketing.png')}
                          alt="alt"
                        />
                        <p>Digital Marketing</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(section.current, 'branding');
                        }}
                        style={styles.fadeInUp3}
                      >
                        <img
                          src={require('../../images/service/branding.png')}
                          alt="alt"
                        />
                        <p>Branding</p>
                      </div>
                    </div>
                  </div>
                </StyleRoot>
              )}
              {// section.current==='web'
              false && (
                <StyleRoot>
                  <div className={sp.serv_pop_right_half_inner}>
                    <img
                      className={sp.back_arrow}
                      onClick={() => {
                        changePage('', section.previous);
                      }}
                      src={require('../../images/service/back-arrow.png')}
                      alt="alt"
                    />
                    <h3 className={sp.serv_pop_right_half_heading}>
                      Web Design And Development
                    </h3>

                    <div
                      className={sp.box_container}
                      style={{ marginTop: '50px' }}
                    >
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(
                            section.current,
                            'form',
                            'Ecommerce Website',
                          );
                        }}
                        style={styles.fadeInUp1}
                      >
                        <img
                          src={require('../../images/service/ecommerce-website.png')}
                          alt="alt"
                        />
                        <p>Ecommerce Website</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(section.current, 'form', 'Static Website');
                        }}
                        style={styles.fadeInUp2}
                      >
                        <img
                          src={require('../../images/service/static-website.png')}
                          alt="alt"
                        />
                        <p>Static Website</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(
                            section.current,
                            'form',
                            'Dynamic Website',
                          );
                        }}
                        style={styles.fadeInUp3}
                      >
                        <img
                          src={require('../../images/service/dynamic-website.png')}
                          alt="alt"
                        />
                        <p>Dynamic Website</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(
                            section.current,
                            'form',
                            'Web App Development',
                          );
                        }}
                        style={styles.fadeInUp4}
                      >
                        <img
                          src={require('../../images/service/web-app-and-development.png')}
                          alt="alt"
                        />
                        <p>Web App Development</p>
                      </div>
                    </div>
                  </div>
                </StyleRoot>
              )}
              {// section.current==='branding'
              false && (
                <StyleRoot>
                  <div className={sp.serv_pop_right_half_inner}>
                    <img
                      className={sp.back_arrow}
                      onClick={() => {
                        changePage('', section.previous);
                      }}
                      src={require('../../images/service/back-arrow.png')}
                      alt="alt"
                    />
                    <h3 className={sp.serv_pop_right_half_heading}>Branding</h3>
                    <div
                      className={sp.box_container}
                      style={{ marginTop: '50px' }}
                    >
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(section.current, 'form', 'Logo Design');
                        }}
                        style={styles.fadeInUp1}
                      >
                        <img
                          src={require('../../images/service/logo-design.png')}
                          alt="alt"
                        />
                        <p>Logo Design</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(
                            section.current,
                            'form',
                            'Brochure Design',
                          );
                        }}
                        style={styles.fadeInUp2}
                      >
                        <img
                          src={require('../../images/service/brochure-design.png')}
                          alt="alt"
                        />
                        <p>Brochure Design</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(
                            section.current,
                            'form',
                            'Promotional Video',
                          );
                        }}
                        style={styles.fadeInUp3}
                      >
                        <img
                          src={require('../../images/service/promotional-video.png')}
                          alt="alt"
                        />
                        <p>Promotional Video</p>
                      </div>
                    </div>
                  </div>
                </StyleRoot>
              )}
              {// section.current==='digital'
              false && (
                <StyleRoot>
                  <div className={sp.serv_pop_right_half_inner}>
                    <img
                      className={sp.back_arrow}
                      onClick={() => {
                        changePage('', section.previous);
                      }}
                      src={require('../../images/service/back-arrow.png')}
                      alt="alt"
                    />
                    <h3 className={sp.serv_pop_right_half_heading}>
                      Digital Marketing
                    </h3>
                    <div
                      className={sp.box_container}
                      style={{ marginTop: '25px' }}
                    >
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(section.current, 'form', 'SEO Services');
                        }}
                        style={styles.fadeInUp1}
                      >
                        <img
                          src={require('../../images/service/seo-img.png')}
                          alt="alt"
                        />
                        <p>SEO Services</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(
                            section.current,
                            'form',
                            'Social Media Marketing',
                          );
                        }}
                        style={styles.fadeInUp2}
                      >
                        <img
                          src={require('../../images/service/social-media-market.png')}
                          alt="alt"
                        />
                        <p>Social Media Marketing</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(
                            section.current,
                            'form',
                            'Email Marketing',
                          );
                        }}
                        style={styles.fadeInUp3}
                      >
                        <img
                          src={require('../../images/service/email-marketing.png')}
                          alt="alt"
                        />
                        <p>Email Marketing</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(section.current, 'form', 'PPC Services');
                        }}
                        style={styles.fadeInUp4}
                      >
                        <img
                          src={require('../../images/service/ppc.png')}
                          alt="alt"
                        />
                        <p>PPC Services</p>
                      </div>
                      <div
                        className={sp.half_width_box}
                        onClick={() => {
                          changePage(
                            section.current,
                            'form',
                            'Facebook Advertising',
                          );
                        }}
                        style={styles.fadeInUp5}
                      >
                        <img
                          src={require('../../images/service/facebook-advertising.png')}
                          alt="alt"
                        />
                        <p>Facebook Advertising</p>
                      </div>
                    </div>
                  </div>
                </StyleRoot>
              )}
              {// section.current==='form'
              true && (
                <StyleRoot style={{ height: 'inherit' }}>
                  <div className={sp.serv_pop_right_half_inner}>
                    {/* <img
                      className={sp.back_arrow}
                      onClick={() => {
                        changePage('home', section.previous);
                      }}
                      src={require('../../images/service/back-arrow.png')}
                      alt="alt"
                    /> */}
                    {/* <h3 className={sp.serv_pop_right_half_heading}>
                      Customer Information
                    </h3> */}
                    <h3 className={sp.serv_pop_right_half_heading}>
                      Enquire Form
                    </h3>
                    <div className={sp.form_container}>
                      <input
                        type="text"
                        id="consultname"
                        ref={nameRef}
                        className={sp.cardtxtfld}
                        onChange={e => setName(e.target.value)}
                        name="fullName"
                        placeholder="Full Name"
                        value={name}
                        style={styles.fadeInUp1}
                      />
                      <input
                        type="text"
                        id="businessname"
                        ref={businessnameRef}
                        className={sp.cardtxtfld}
                        onChange={e => setbusinessName(e.target.value)}
                        name="businessName"
                        placeholder="Business Name"
                        value={businessname}
                        autoComplete="false"
                        style={styles.fadeInUp1}
                      />
                      <div style={styles.fadeInUp2}>
                        <PhoneInput
                          className="col-ls-12"
                          country="in"
                          enableSearch
                          disableSearchIcon
                          placeholder="Phone Number"
                          inputStyle={{
                            width: '100%',
                            padding: '22px 42px',
                            border: phonenumberErr
                              ? 'red solid 2px'
                              : 'solid 1px #CACACA',
                          }}
                          // inputClass={sp.inputphonenum}
                        //  className="usermobile"
                          preferredCountries={['in', 'us', 'sg']}
                          priority={{ in: 0, us: 1, sg: 2 }}
                          onChange={(value, country) =>
                            setValues(value, country)
                          }
                          value={phone}
                        />
                      </div>

                      <input
                        type="email"
                        ref={emailRef}
                        className={sp.cardtxtfld}
                        onChange={e => setEmail(e.target.value)}
                        name="email"
                        placeholder="Email Address"
                        value={email}
                        style={styles.fadeInUp3}
                      />
                      {/* <input
                        type="text"
                        ref={companyNameRef}
                        className={sp.cardtxtfld}
                        onChange={e => setCompanyName(e.target.value)}
                        name="companyName"
                        placeholder="Company Name"
                        value={companyName}
                        style={styles.fadeInUp4}
                      /> */}
                      {/* <select
                        className={sp.drpdwn}
                        onChange={e => setService(e.target.value)}
                        ref={serviceRef}
                        style={styles.fadeInUp4}
                      >
                        <option disabled selected>
                          Select service
                        </option>
                        <option>Web Design And Development</option>
                        <option>Digital Marketing</option>
                        <option>Branding</option>
                      </select> */}

                      <Select
                      //className={s.dropdowns_set}
                      ref={serviceRef}
                      onChange={onChange}
                      options={options}
                      //value={values}
                      styles={customStyles}
                      maxMenuHeight={150}
                    />
                      {/* {service && (
                        <select
                          className={sp.drpdwn}
                          onChange={e => setSelectedService(e.target.value)}
                          ref={selectedServiceRef}
                        >
                          {service === 'Web Design And Development' && (
                            <>
                              <option disabled selected>
                                Please Select
                              </option>
                              <option>Ecommerce Website</option>
                              <option>Static Website</option>
                              <option>Dynamic Website</option>
                              <option>Web App Development</option>
                            </>
                          )}
                          {service === 'Digital Marketing' && (
                            <>
                              <option disabled selected>
                                Please Select
                              </option>
                              <option>SEO Services</option>
                              <option>Social Media Marketing</option>
                              <option>Email Marketing</option>
                              <option>PPC Services</option>
                              <option>Facebook Advertising</option>
                            </>
                          )}
                          {service === 'Branding' && (
                            <>
                              <option disabled selected>
                                Please Select
                              </option>
                              <option>Logo Design</option>
                              <option>Brochure Design</option>
                              <option>Promotional Video</option>
                            </>
                          )}
                        </select>
                      )} */}
                      <textarea
                        className={sp.cardtxtfld}
                        ref={commentRef}
                        placeholder="Your Comments"
                        onChange={e => setComments(e.target.value)}
                        value={comments}
                        style={styles.fadeInUp5}
                      />
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                       // style={styles.fadeInUp6}
                      >
                        <button
                          id="smtbtn"
                          className={sp.submit_btn}
                          onClick={() => handleSubmit()}
                        >
                          <CircularProgress
                            className="loader"
                            style={{
                              display: 'none',
                              position: 'absolute',
                              color: 'white',
                              height: '20px',
                              width: '20px',
                              margin: '5px',
                            }}
                          />{' '}
                          <span>Submit</span>
                        </button>
                        <p
                          id="scs_msg"
                          style={{
                            position: 'absolute',
                            color: 'darkgreen',
                            left: '0',
                            right: '0',
                            bottom:'0',
                            display: 'none',
                          }}
                        >
                          Successfully Submitted
                        </p>
                      </div>
                    </div>
                  </div>
                </StyleRoot>
              )}
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default ServicePopup;
